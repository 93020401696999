import React, { useEffect, useState } from 'react'

import { Container, Row, Col, Badge, ListGroup } from 'react-bootstrap'
import styled from "styled-components";

var XMLParser = require('react-xml-parser')

export const Title = styled.h2`
    font-size: 30px;
    text-decoration: underline;
    text-decoration-color: rgba(238, 84, 58, 0.8);
    text-decoration-thickness: 5px;
    text-underline-offset: 8px;
    display: inline-block;
    padding: 2px 10px 2px 10px;
    border-radius: 5px;
    color: #000;
    margin-bottom: 50px;
    font-weight: 900;
`

const StyledContainer = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #FFF;
  border-top: 1px solid #ddd;

  .fw-bold a {
    color: #000;
    text-decoration: none;
  }

  .fw-bold a:hover {
    color: #000;
    text-decoration: underline;
  }
`

const Type = styled("span") <{ active?: boolean }>`
    padding-right: 10px;
    color: ${props => (props.active ? '#000' : '#222')};
    font-weight: ${props => (props.active ? '500' : '200')};
    cursor: pointer;
    font-size: 20px;
  
    :hover {
      color: #000;
    }
`

const PublicationItem = styled.p`
    font-size: 20px;
    font-weight: 300;
    margin:0;
    padding:0;
`

const PublicationTitle = styled.span`
  color: rgba(238, 84, 58, 0.8);

  a {
    color: rgba(238, 84, 58, 1);
    text-decoration: none;

    :hover {
      color: rgba(238, 84, 58, 1);
      text-decoration: underline;
      text-underline-offset: 3px;
    }
  }
`

const PublicationAuthors = styled.span`
    margin-right: 5px;
`

const MyBadge = styled(Badge)`
  background-color: rgba(238, 84, 58, 1) !important;
  color: #FFF !important;
`

const DateBadge = styled(Badge)`
  background-color: rgba(238, 84, 58, 1) !important;
  color: #FFF !important;
`

const AccessBadge = styled(Badge)`
  background-color: #2C82C9 !important;
  color: #FFF !important;
  margin-left: 5px;
`

const BadgeWrapper = styled.div`
  text-align: right;
  margin-left: 5px;
`




const Publications = () => {
    const [type, set_type] = useState("all")

    const pubs = [
        {
            type: "journal",
            data: {
                authors: "F. Ihle, S. Lindner, M. Menth",
                title: "P4-PSFP: P4-Based Per-Stream Filtering and Policing for Time-Sensitive Networking",
                venue: "IEEE Transactions on Network and Service Management (TNSM)",
                venue_link: "https://www.comsoc.org/publications/journals/ieee-tnsm",
                paper_link: "https://arxiv.org/abs/2311.07385",
                year: 2024,
                month: 7
            }
        },
        {
            type: "conference",
            data: {
                authors: "L. Osswald, S. Lindner, L. Bechtel, T. Heer, M. Menth",
                title: "Secure Resource Allocation Protocol (SecRAP) for Time-Sensitive Networking",
                venue: "IEEE International Conference on Emerging Technologies and Factory Automation (ETFA)",
                venue_link: "https://2024.ieee-etfa.org",
                note: "Best Paper Award",
                month: 9,
                year: 2024
            }
        },
        {
            type: "journal",
            data: {
                authors: "M. Flüchter, S. Lindner, L. Osswald, J. Arnaud, M. Menth",
                title: "Autonomous Integration of TSN-unaware Applications with QoS Requirements in TSN Networks",
                paper_link: "https://doi.org/10.1016/j.comcom.2024.04.021",
                venue: "Computer Communications",
                venue_link: "https://www.sciencedirect.com/journal/computer-communications",
                month: 4,
                year: 2024
            }
        },
        {
            type: "other",
            data: {
                authors: "S. Lindner",
                title: "Advances for Resilient Real-Time Networks using Network Softwarization",
                paper_link: "http://dx.doi.org/10.15496/publikation-92488",
                venue: "Dissertation",
                month: 2,
                year: 2024
            }
        },
        {
            type: "journal",
            data: {
                authors: "T. Stueber, L. Osswald, S. Lindner, M. Menth",
                title: "A Survey of Scheduling Algorithms for the Time-Aware Shaper in Time-Sensitive Networking (TSN)",
                paper_link: "https://ieeexplore.ieee.org/document/10151872",
                venue: "IEEE Access",
                venue_link: "https://ieeexplore.ieee.org/xpl/RecentIssue.jsp?punumber=6287639",
                month: 6,
                year: 2023
            }
        },
        {
            type: "journal",
            data: {
                authors: "F. Hauser, M. Häberle, D. Merling, S. Lindner, V. Gurevich, F. Zeiger, R. Frank, and M. Menth",
                title: "A Survey on Data Plane Programming with P4: Fundamentals, Advances, and Applied Research",
                paper_link: "https://www.sciencedirect.com/science/article/abs/pii/S1084804522002028?via%3Dihub",
                venue: "Journal of Network and Computer Applications (JNCA)",
                venue_link: "https://www.journals.elsevier.com/journal-of-network-and-computer-applications",
                month: 3,
                year: 2023,
            }
        },
        {
            type: "journal",
            data: {
                authors: "S. Lindner, M. Häberle, and M. Menth",
                title: "P4TG: 1 Tb/s Traffic Generation for Ethernet/IP Networks",
                paper_link: "https://ieeexplore.ieee.org/document/10048513",
                venue: "IEEE Access",
                venue_link: "https://ieeexplore.ieee.org/xpl/RecentIssue.jsp?punumber=6287639",
                month: 2,
                year: 2023
            }
        },
        {
            type: "journal",
            data: {
                authors: "S. Lindner, D. Merling, and M. Menth",
                title: "Learning Multicast Patterns for Efficient BIER Forwarding with P4",
                paper_link: "https://ieeexplore.ieee.org/document/10004199",
                venue: "IEEE Transactions on Network and Service Management (TNSM)",
                venue_link: "http://www.comsoc.org/eTNSM/",
                month: 6,
                year: 2023
            }
        },
        {
            type: "journal",
            data: {
                authors: "S. Lindner, G. Paradzik, and M. Menth",
                title: "Alternative Best Effort (ABE) for Service Differentiation: Trading Loss versus Delay",
                paper_link: "https://ieeexplore.ieee.org/document/9954450",
                venue: "IEEE/ACM Transactions on Networking (ToN)",
                venue_link: "https://sites.google.com/view/ieee-acm-ton/home",
                month: 8,
                year: 2023,
            }
        },
        {
            type: "journal",
            data: {
                authors: "D. Merling, S. Lindner, and M. Menth",
                title: "Robust LFA Protection for Software-Defined Networks (RoLPS)",
                paper_link: "https://atlas.informatik.uni-tuebingen.de/~menth/papers/Menth21e.pdf",
                venue: "IEEE Transactions on Network and Service Management (TNSM)",
                venue_link: "http://www.comsoc.org/eTNSM/",
                month: 9,
                year: 2021,
            }
        },
        {
            type: "journal",
            data: {
                authors: "D. Merling, S. Lindner, and M. Menth",
                title: "Hardware-based Evaluation of Scalable and Resilient Multicast with BIER in P4",
                paper_link: "https://atlas.informatik.uni-tuebingen.de/~menth/papers/Menth21e.pdf",
                venue: "IEEE Access",
                venue_link: "https://ieeexplore.ieee.org/xpl/RecentIssue.jsp?punumber=6287639",
                month: 3,
                year: 2021,
            }
        },
        {
            type: "journal",
            data: {
                authors: "D. Merling, S. Lindner, and M. Menth",
                title: "P4-Based Implementation of BIER and BIER-FRR for Scalable and Resilient Multicast in P4",
                paper_link: "https://doi.org/10.1016/j.jnca.2020.102764",
                venue: "Journal of Network and Computer Applications (JNCA)",
                venue_link: "https://www.journals.elsevier.com/journal-of-network-and-computer-applications",
                month: 11,
                year: 2020,
            }
        },
        {
            type: "conference",
            data: {
                authors: "L. Osswald, S. Lindner, L. Wuesteney, and M. Menth",
                title: "RAP Extensions for the Hybrid Configuration Model",
                paper_link: "https://ieeexplore.ieee.org/document/9613246",
                venue: "IEEE International Conference on Emerging Technologies and Factory Automation (ETFA)",
                venue_link: "https://2021.ieee-etfa.org/",
                month: 9,
                year: 2021,
            }
        },
        {
            type: "conference",
            data: {
                authors: "D. Merling, S. Lindner, and M. Menth",
                title: "Comparison of Fast-Reroute Mechanisms for BIER-Based IP Multicast",
                paper_link: "https://doi.org/10.1109/SDS49854.2020.9143935",
                venue: "International Conference on Software Defined Systems (SDS)",
                venue_link: "http://emergingtechnet.org/SDS2020",
                month: 6,
                year: 2020,
            }
        },
        {
            type: "workshop",
            data: {
                authors: "S. Lindner, D. Merling, M. Häberle, and M. Menth",
                title: "P4-Protect: 1+1 Path Protection for P4",
                paper_link: "https://dl.acm.org/doi/10.1145/3426744.3431327",
                venue: "P4 Workshop in Europe",
                venue_link: "https://p4.org/events/2020-12-01-euro-p4-workshop/",
                month: 12,
                year: 2020,
            }
        },
        {
            type: "workshop",
            data: {
                authors: "S. Lindner, M. Haeberle, F. Heimgaertner, N. Nayak, S. Schildt, D. Grewe, H. Loehr, and M. Menth",
                title: "P4 In-Network Source Protection for Sensor Failover",
                paper_link: "https://ieeexplore.ieee.org/abstract/document/9142735",
                venue: "International Workshop on Time-Sensitive and Deterministic Networking (TENSOR)",
                venue_link: "https://networking.ifip.org/2020/workshops/tensor-2020",
                month: 6,
                year: 2020,
            }
        },

    ]

    const selectType = (type: string) => {
        let data: any = []
        set_type(type)
    }

    const sortPubs = (a: { data: { year: number, month: number } }, b: { data: { year: number, month: number } }) => {
        if (a.data.year < b.data.year) {
            return -1
        }
        if (a.data.year == b.data.year) {
            if (a.data.month < b.data.month) {
                return -1
            }
            else {
                return 1
            }
        }
        else {
            return 1
        }
    }

    const monthToString = (month: number) => {
        let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

        return months[month - 1]
    }

    return <StyledContainer className="mb-5">
        <Container fluid={"xl"}>
            <Row>
                <Col className="text-center"><Title>Publications</Title></Col>
            </Row>
            <Row className={"mb-3"}>
                <Col className={""}>
                    <Type active={type === "all"} onClick={() => selectType("all")}>All</Type>
                    <Type active={type === "journal"} onClick={() => selectType("journal")}>Journal</Type>
                    <Type active={type === "conference"} onClick={() => selectType("conference")}>Conference</Type>
                    <Type active={type === "workshop"} onClick={() => selectType("workshop")}>Workshop</Type>
                </Col>
            </Row>
            <ListGroup as="ol" numbered>
                {pubs.sort(sortPubs).reverse().filter((v) => v.type == type || type == "all").map((pub: any, index: number) => {
                    return <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >
                        <div className="ms-2 me-auto">
                            <div className="fw-bold"><a target="_blank" href={pub.data.paper_link}>{pub.data.title}</a></div>
                            <PublicationAuthors>{pub.data.authors}</PublicationAuthors>
                            <br />
                            <PublicationTitle><a target="_blank" href={pub.data.venue_link}>{pub.data.venue}</a></PublicationTitle>
                        </div>

                        <BadgeWrapper>
                            <DateBadge pill>
                                {monthToString(pub.data.month)}, {pub.data.year}
                            </DateBadge>

                            {pub.data.hasOwnProperty("note") ?
                                <AccessBadge pill>
                                    {pub.data.note}
                                </AccessBadge>
                                :
                                null
                            }
                        </BadgeWrapper>
                    </ListGroup.Item>

                })}
            </ListGroup>
        </Container>


    </StyledContainer>
}

export default Publications;
