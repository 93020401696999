import React from 'react';
import logo from './logo.svg';
import './App.css';

import Navbar from "./components/Navbar"
import Home from './sites/Home';
import { Container } from 'react-bootstrap';
import Education from './sites/Education';
import Publications from './sites/Publications';
import Skills from './sites/Skills';
import Impressum from './sites/Impressum';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './components/Footer';
import Research from './sites/Research';

const App = () => {
  return <Router>
    <Routes>
      <Route path={"/"} element={
        <>
          <Navbar />
          <Home />
          <Education />
          <Skills />
          <Publications />
          <Research />
          <Footer />
        </>
      } />
      <Route path={"/impressum"} element={<>
        <Navbar />
        <Impressum />
        <Footer />
      </>
      } />
    </Routes>

  </Router>
}

export default App;
