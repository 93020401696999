import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import styled from 'styled-components'

const Bar = styled.div`
    background-color: #FFF;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #ddd;
`

const StyledImg = styled.img`
    width: 15px;
    margin-top: -2px;
`

const Navbar = () => {
    return <Bar>
        <Container fluid="xl">
            <Row>
                <Col className="my-auto text-center">
                    <StyledImg src="./favicon.png" /> Dr. Steffen Lindner | Personal Website
                </Col>

            </Row>
        </Container>
    </Bar>
}

export default Navbar;
