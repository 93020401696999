import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import styled from 'styled-components'

import { Link } from 'react-router-dom'

const StyledContainer = styled.div`
    padding-top: 10px;
    background: #FFF;
`

const StyledLink = styled(Link)`
    color: #3d3d3d;
    font-weight: 300;
    text-decoration: none;
    padding: 10px;
  
    :hover {
      color: #000;
    }
`

const Footer = () => {
    return <StyledContainer>
        <Container fluid={"xxl"}>
            <Row>
                <Col className={"text-end mb-3"}>
                    <StyledLink to={"/"}>Home</StyledLink>
                    <StyledLink to={"/impressum"}>Impressum</StyledLink>
                </Col>
            </Row>
        </Container>
    </StyledContainer>
}

export default Footer;