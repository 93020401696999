import React from "react"
import { Col, Container, Row } from "react-bootstrap"

import styled from 'styled-components'

import avatar from '../assets/img/me.png'
import github from '../assets/img/github.png'
import linkedin from '../assets/img/linkedin.png'
import dblp from '../assets/img/dblp.png'
import orcid from '../assets/img/orcid.png'


const RefLogo = styled.img`
    width: 25px;
    margin-right: 15px;
`

const Avatar = styled.img`
    margin-bottom: 15px;
`


const Wrapper = styled.div`
    margin-top: 50px;
    margin-bottom: 15px;
    font-weight: 200;

    span {
        padding: 2px 5px 2px 5px;
        border-radius: 5px;
        font-weight: 900;
        text-decoration: underline;
        text-decoration-color: rgba(238, 84, 58, 0.8);
        text-decoration-thickness: 5px;
        text-underline-offset: 3px;
    }

    a img {
        cursor: pointer;
        transition: all .2s ease-in-out;

        :hover {
            transform: scale(1.2);
        }
    }
`
const Home = () => {
    return <Container fluid="xl">
        <Wrapper>
            <Row>
                <Col md={{ span: 7, order: 'first', offset: 0 }} xs={{ span: 12, order: 'last' }}>
                    <h2>Hi, I'm <span>Dr. Steffen Lindner</span></h2>
                    <p>I am a computer science graduate and received my Ph.D. (Dr. rer. nat.) in the field of communication networks. I am interested in software-defined networks, congestion management and switch programming in P4. Aside from my academic career, I am a dedicated app & web developer.</p>

                    <Row>
                        <Col>
                            <a href={"https://github.com/NE4Y"}><RefLogo src={github} alt={"Github"} /></a>
                            <a href={"https://www.linkedin.com/in/steffenlindner96/"}><RefLogo src={linkedin} alt={"LinkedIn"} /></a>
                            <a href={"https://orcid.org/0000-0002-5274-4621"}><RefLogo src={orcid} alt={"orcid"} /></a>
                            <a href={"https://dblp.org/pid/257/4950.html"}><RefLogo src={dblp} alt={"dblp"} /></a>
                        </Col>
                    </Row>
                </Col>
                <Col className="text-center" md={{ span: 4, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                    <Avatar src={avatar} alt={"It's me"} />
                </Col>
            </Row>
        </Wrapper>
    </Container>
}

export default Home
