import React from 'react'

import { Container, Row, Col } from 'react-bootstrap'
import styled from "styled-components";


const StyledContainer = styled.div`
  background: #FFF;
  width: 100%;
  color: #000;
  font-family: 'Arial', serif;
  font-size: 18px;
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
  box-sizing: content-box;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
`

const CVEvent = styled.h2`
  font-size: 18px;
  font-weight: 900;
`

const CVDescription = styled.p`
  color: #222;
  font-weight: 300;
`

const CVDate = styled.p`
  color: #222;
  font-weight: 300;
  margin-bottom:0;
`

const Point = styled.div`
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background-color: rgba(238, 84, 58, 1);
    display: inline-block;
`

const CVLine = styled.div`
    width: 3px;
    background-color: rgba(238, 84, 58, 0.8);
    height:calc(100% - 115px);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 57px;
`


const Education = () => {
    return <>
        <StyledContainer>
            <Container fluid={"xxl"}>
                <Row className={"mb-4"}>
                    <Col className={"text-end col-55"}>
                        <CVEvent>B.Sc. Computer Science</CVEvent>
                        <CVDescription>Graduated at University of Tuebingen: 1.4</CVDescription>
                    </Col>
                    <Col className={"text-center col-auto p-0"}><Point /></Col>
                    <Col className={"text-start col-55"}>
                        <CVDate>2014-2017</CVDate></Col>
                </Row>
                <Row className={"mb-4"}>
                    <Col className={"text-end col-55"}>
                        <CVDate>2017-2019</CVDate>
                    </Col>
                    <Col className={"text-center col-auto p-0"}><Point /></Col>
                    <Col className={"text-start col-55"}>
                        <CVEvent>M.Sc. Computer Science</CVEvent>
                        <CVDescription>Graduated at University of Tuebingen: 1.0</CVDescription>
                    </Col>

                </Row>
                <Row className={"mb-4"}>
                    <Col className={"text-end col-55"}>
                        <CVEvent>Ph.D. Student & Research assistant</CVEvent>
                        <CVDescription>Research assistant at the Department of Computer Science, Chair of Communication Networks.</CVDescription>
                    </Col>
                    <Col className={"text-center col-auto p-0"}><Point /></Col>
                    <Col className={"text-start col-55"}><CVDate>2019-2024</CVDate></Col>
                </Row>
                <Row className={"mb-4"}>
                    <Col className={"text-end col-55"}>
                        <CVDate>2019-2024</CVDate>
                    </Col>
                    <Col className={"text-center col-auto p-0"}><Point /></Col>
                    <Col className={"text-start col-55"}>
                        <CVEvent>Dr. rer. nat. (Computer Science)</CVEvent>
                        <CVDescription>Graduated at University of Tuebingen with honors (summa cum laude)</CVDescription>
                    </Col>
                </Row>
                <Row>
                    <Col className={"text-end col-55"}>
                    </Col>
                    <Col className={"text-center col-auto p-0"}><Point /></Col>
                    <Col className={"text-start col-55"}><CVDate>Today</CVDate></Col>
                </Row>
            </Container>
            <CVLine />
        </StyledContainer>
    </>
}

export default Education;
