import React from 'react'

import styled from 'styled-components'

import { Container, Row, Col } from 'react-bootstrap'

const StyledContainer = styled.div`
  padding-top: 40px;
  color: #131313;
  background: #FFF;
  flex:auto;
  padding-bottom: 40px;
`

const Impressum = () => {
    return <StyledContainer>
        <Container fluid={"xl"}>
            <h2>Impressum</h2>
            <h4>Angaben gemäß §5 TMG</h4>

            <Row>
                <Col>Dr. Steffen Lindner</Col>
            </Row>
            <Row>
                <Col>Draustraße 11</Col>
            </Row>
            <Row>
                <Col>64347 Griesheim</Col>
            </Row>

            <Row className={"mt-4"}>
                <h4>Kontakt</h4>
            </Row>

            <Row>
                <Col className={"col-auto"}>Telefon:</Col>
                <Col className={"text-start"}>06155 8348648</Col>
            </Row>
            <Row>
                <Col className={"col-auto"}>Email:</Col>
                <Col className={"text-start"}>contact@steffen-lindner.net</Col>
            </Row>

        </Container>
    </StyledContainer>
}

export default Impressum;
