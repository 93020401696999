import React from "react"
import { Button, Card, Col, Container, Row } from "react-bootstrap"
import { Title } from "./Publications"

import sdn from '../assets/img/SDN.png'
import p4 from "../assets/img/P4.png"
import dscd from "../assets/img/dscd.png"

import styled from "styled-components"

const MyCard = styled(Card)`
    cursor: pointer;
    transition: all .2s ease-in-out;

    :hover {
        transform: scale(1.05);
    }
`

const Research = () => {
    return <Container fluid="xl" className="mb-5">
        <Row>
            <Col className="text-center">
                <Title>Research Interests</Title>
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={4} className="mb-3 d-flex align-items-stretch">
                <MyCard>
                    <Card.Img className="p-2" variant="top" src={sdn} />
                    <Card.Body>
                        <Card.Title>Software-Defined Networks</Card.Title>
                        <Card.Text>
                            Software-Defined Networking (SDN) is an emerging architecture that is dynamic, manageable, cost-effective, and adaptable, making it ideal for the high-bandwidth, dynamic nature of today’s applications.
                            This architecture decouples the network control and forwarding functions enabling the network control to become directly programmable and the underlying infrastructure to be abstracted for applications and network services.
                        </Card.Text>
                    </Card.Body>
                </MyCard>
            </Col>

            <Col xs={12} md={4} className="mb-3 d-flex align-items-stretch">
                <MyCard>
                    <Card.Img className="p-2" variant="top" src={p4} />
                    <Card.Body>
                        <Card.Title>P4: Programming Protocol-Independent Packet Processors</Card.Title>
                        <Card.Text>
                            P4 is a programming language for controlling packet forwarding planes in networking devices, such as routers and switches. In contrast to a general purpose language such as C or Python, P4 is a domain-specific language with a number of constructs optimized for network data forwarding.
                        </Card.Text>
                    </Card.Body>
                </MyCard>
            </Col>
            <Col xs={12} md={4} className="mb-3 d-flex align-items-stretch">
                <MyCard>
                    <Card.Img className="p-2" variant="top" src={dscd} />
                    <Card.Body>
                        <Card.Title>Congestion Management</Card.Title>
                        <Card.Text>
                            Network congestion in data networking and queueing theory is the reduced quality of service that occurs when a network node or link is carrying more data than it can handle. Typical effects include queueing delay, packet loss or the blocking of new connections. A consequence of congestion is that an incremental increase in offered load leads either only to a small increase or even a decrease in network throughput.
                        </Card.Text>
                    </Card.Body>
                </MyCard>
            </Col>
        </Row>
    </Container>

}

export default Research