import React from "react"
import { Col, Container, ProgressBar, Row } from "react-bootstrap"

import styled from "styled-components"
import { Title } from "./Publications"

const Wrapper = styled.div`
    font-weight: 200;

    span {
        padding: 2px 5px 2px 5px;
        border-radius: 5px;
        font-weight: 900;
        text-decoration: underline;
        text-decoration-color: rgba(238, 84, 58, 0.8);
        text-decoration-thickness: 5px;
        text-underline-offset: 3px;
    }

    .progress {
        background-color: #FFF;
        border: 1px solid #d8d8d8;
    }

    .progress-bar {
        background-color: rgba(238, 84, 58, 0.8);
    }


    h5 {
        font-weight: 300;
    }

`

const Skills = () => {
    return <Container fluid="xl">
        <Wrapper className="mb-5 mt-5">
            <Row>
                <Col className="text-center">
                    <Title>My Skills</Title>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col xs={{ span: 12 }} md={{ span: 6 }} className="mb-3">
                    <h5>Research & Teaching</h5>
                    <ProgressBar striped animated now={85} />
                </Col>

                <Col xs={{ span: 12 }} md={{ span: 6 }}>
                    <h5>Communication Networks</h5>
                    <ProgressBar striped animated now={95} />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={{ span: 12 }} md={{ span: 6 }} className="mb-3">
                    <h5>P4</h5>
                    <ProgressBar striped animated now={95} />
                </Col>

                <Col xs={{ span: 12 }} md={{ span: 6 }}>
                    <h5>HTML / CSS / JS / NodeJS / React</h5>
                    <ProgressBar striped animated now={80} />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={{ span: 12 }} md={{ span: 6 }} className="mb-3">
                    <h5>Python</h5>
                    <ProgressBar striped animated now={90} />
                </Col>

                <Col xs={{ span: 12 }} md={{ span: 6 }}>
                    <h5>Rust</h5>
                    <ProgressBar striped animated now={70} />
                </Col>
            </Row>
        </Wrapper>
    </Container>
}

export default Skills
